.studio-container {
    display: flex;
    height: fit-content;
  }
  
  /* Scrollable video list on the left */
  .video-list {
    width: 20%;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
    padding: 10px;
    overflow-y: auto;
  }
  
  .video-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .video-thumbnail img {
    height: 100%;
    width: 100%;
    max-height:100px;
    max-width: 100px;
  }
  
  .video-thumbnail p {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  
  /* Main editor area */
  .editor-area {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  /* Action buttons bar */
  .action-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  /* Frame bar to display frames */
  .frame-bar {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .frame img {
    width: 60px;
    height: 60px;
  }
  
  .frame p {
    margin-top: 5px;
    font-size: 12px;
  }
  
  /* Canvas preview area */
  .canvas-preview {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .canvas-preview img {
    max-height: 350px;
    max-width: 100%;
    height: 100%;
  }

  .cut-inputs {
    width: 100%;
  }
  
  .canvas-preview p {
    font-size: 18px;
    color: #666;
  }
  