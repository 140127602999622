.homepage-container {
    padding: 0px;
    width: 100%;
  }
  
  .homepage-header {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  
  .homepage-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;

  }
  .app-store-button-container {
    display: grid;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
  }

  .video-item {
    margin-bottom: 20px;
  }
  
  .video-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .video-item {
    flex: 1 1 calc(33% - 10px);
  }
  
  .sections-bar {
    background-color: #f0f0f0; /* Or your preferred color */
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 10; 
  }
  
  .sections-bar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .sections-bar li {
    margin-right: 20px;
  }
  
  .sections-bar a {
    text-decoration: none;
    color: #333;
  }
  .homepage-footer {
    text-align: center;
    margin-top: 20px;
  }
  .typewriter {
    color: rgba(255, 255, 255, 0.8);
  }
  .typewriter-text {
    color: rgba(255, 255, 255, 0.8);
  }
  .Typewriter span {
    color: rgba(204, 19, 87, 0.64); /* Change this to your desired color */
  }
  .typewriter-text span {
    color: rgba(255, 255, 255, 0.5); /* Change this to your desired color */
  }
  .typewriter.string {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .typewriter.cursor {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .string {
    color: rgba(255, 255, 255, 0.8);
  }

  .string-facebook {
    color: rgba(66, 133, 244, 0.8);
  }
  
  .string-instgram {
    color: rgba(255, 56, 96, 0.8);
  }
  
  .string-snapchat {
    color: rgba(255, 193, 7, 0.8);
  }
  
  .string-tiktok {
    color: rgba(44, 152, 224, 0.8);
  }
  
  .string-kwai {
    color: rgba(255, 0, 0, 0.8);
  }

/* Custom scrollbar styles */
.custom-scrollbar {
  overflow: auto;
  max-width: 100%;
  max-height: 80vh;
  border: 1px solid #ddd;
  padding: 4px;
  white-space: nowrap;
  WebkitOverflowScrolling: 'touch'; /* for smooth scrolling on iOS */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #ddd; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #ddd; /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #ddd; /* Padding around thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

.homepage-header-app {
  background: linear-gradient(181deg, #FF6B81, #FFA07A);

}
.Typewriter span {
  color: #f37717;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .custom-scrollbar {
    scrollbar-width: none; /* Hide the scrollbar on mobile */
    -ms-overflow-style: none;  /* IE and Edge */
  }
  .custom-scrollbar::-webkit-scrollbar {
    display: none; /* Hide the scrollbar on mobile */
  }
}
